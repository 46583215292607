.header-section {
    padding-top: 24px;
    padding-bottom: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.section-wraper {
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    padding-left: 40px;
    padding-right: 40px;
}

.ai-logo {

    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 24px;
}

.header-nav {
    display: flex;
    align-items: center;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 15px;
    text-align: center;
    letter-spacing: 0.02em;
    color: rgb(0, 0, 0);

}

.header-nav a {
    display: block;
}

.header-nav a:hover {
    font-weight: 500;
}

@media (max-width: 768px) {
    .header-nav {
        display: none !important;
    }

    .responsive-btn {
        display: flex !important;
    }

}

.resp-nav {
    transition: all 0.3s ease-in-out;
    padding-top: 4px;
    background: rgba(255, 255, 255, 0.60);
    backdrop-filter: blur(10px);
}

.resp-nav li {
    padding-bottom: 8px;
    width: 100%;
}

.resp-nav li a {
    display: block;
    padding-bottom: 8px;
}

@media (min-width: 768px) {
    .responsive-btn {
        display: none !important;
    }

    .resp-nav {
        display: none !important;
    }

    .header-nav {
        display: flex !important;
    }
}

h2 {
    text-align: center;
    font-family: Manrope;
    font-size: 56px;
    font-style: normal;
    font-weight: 700;
    line-height: 68px;
    letter-spacing: -1px;
}

p {
    font-weight: 400;
    font-size: 16px;
    line-height: 32px;
    /* text-transform: capitalize; */
}

h3 {
    color: var(--main, #161414);
    font-family: Manrope;
    font-size: 32px;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -1px;
}

@media (max-width: 640px) {
    h3 {
        font-size: 26px;
    }

    p {
        font-size: 14px;
        line-height: 26px;
    }
}

.analyse-btn {
    border-radius: 14px;
    background: var(--a, linear-gradient(180deg, #4384D1 0%, #933EFF 100%));
    color: #ffffff;
    padding: 12px 28px;
    font-weight: 600;
    font-size: 16px;
}

.analyse-btn:hover {
    background: var(--a, linear-gradient(180deg, #933EFF 0%, #4384D1 100%));

}

@media (max-width: 1024px) {

    .text-header3 {
        font-size: 18px;
    }

    h2 {
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 27px;
        letter-spacing: -0.5px;
        /* text-transform: capitalize; */
    }

    .recom-head {
        font-size: 30px;
        font-weight: 600;
    }
}

@media (max-width: 640px) {
    .input-footer {
        display: flex;
        align-items: center;
        flex-direction: column;
    }
}

@media (max-width: 640px) {
    .clear-btn {

        background: #303030;
        border-radius: 8px;
        padding: 12px;
        font-weight: 600;
        font-size: 10px;
    }

    .submit-btn {
        background: #1764C8;
        border-radius: 8px;
        padding: 8px 12px;
        font-weight: 600;
        font-size: 10px;

    }

    .error-div {
        position: absolute;
        display: flex;
        flex-direction: column;
        bottom: 115px;
        height: fit-content;
        width: 100%;
    }

    .length-error,
    .num-error {
        font-size: 10px;
    }

    .analyse-btn {
        background: #1764C8;
        padding: 8px 24px;
        font-weight: 600;
        font-size: 12px;

    }

}

@media (max-width: 351px) {
    .error-div {
        bottom: 130px;
    }

    .text-header3 {
        font-size: 16px;
    }
}

.output-scroll::-webkit-scrollbar {
    width: 10px;
}

/* Track */
.output-scroll::-webkit-scrollbar-track {
    background: #848187;
    cursor: pointer;
}

/* Handle */
.output-scroll::-webkit-scrollbar-thumb {
    background: #49484a;
    cursor: pointer;
}

/* Handle on hover */
.output-scroll::-webkit-scrollbar-thumb:hover {
    background: #3e3d3e;
    cursor: pointer;
}

.out-pl {
    padding-right: 0 !important;
}

.chat-banner {
    background: #F2EFFF;
    border-radius: 16px;
    padding-top: 55px;
    padding-bottom: 0;
    display: flex;
    justify-content: center;
}

.section-wraper1 {
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    padding-left: 40px;
    padding-right: 40px;
}

@media (max-width: 768px) {
    .section-wraper1 {
        max-width: 100%;
        padding-left: 12px;
        padding-right: 12px;
    }

    .section-wraper {
        padding-left: 20px;
        padding-right: 20px;
    }
}

@media (max-width: 420px) {
    .section-wraper {
        max-width: 100%;
        padding-left: 10px;
        padding-right: 10px;
    }
}

@media only screen and (max-width: 1070px) and (min-width: 768px) {
    .chatpadding {
        padding-bottom: 10px !important;
    }
}

.custom-loader {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background: conic-gradient(#0000 10%, #F4F4F4);
    -webkit-mask: radial-gradient(farthest-side, #0000 calc(100% - 4px), #000 0);
    animation: s3 1s infinite linear;
}

@keyframes s3 {
    to {
        transform: rotate(1turn)
    }
}

.swiper-button-next,
.swiper-button-prev {
    position: absolute;
    bottom: 350px !important;
    background-color: white !important;
    color: black !important;
    width: 40px !important;
    height: 40px !important;
    border: 1px solid #000000;
    border-radius: 36px;
    margin-left: 40px;
    margin-right: 40px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    /* color: white !important; */
    font-size: 10px !important;

}

.swiper-button-next:after,
.swiper-button-prev:after {
    font-family: swiper-icons;
    font-size: 20px;
    text-transform: none !important;
    letter-spacing: 0;
    font-variant: initial;
    line-height: 1;
}

/* @media only screen and (max-width: 768px) {

            .swiper-button-next,
            .swiper-button-prev {
                margin-left: -30px !important;
                margin-right: -30px !important;
            }
        } */
.swiper-button-next {
    right: -40px !important;
}

.swiper-button-prev {
    left: -40px !important;
}

/* .swiper-button-disabled {
            display: none !important;
        } */

.swiper-button-next,
.swiper-button-prev {
    background-color: #000000 !important;
    color: white !important;
    font-weight: 700 !important;
    opacity: 0.9;
    outline: none;
}

.secA {
    color: var(--para, #353535);
    text-align: center;
    font-family: Manrope;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0.5px;
}

@media (max-width: 420px) {
    .secA {
        color: var(--para, #353535);
        text-align: center;
        font-family: Manrope;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.5px;
    }
}

.explore {
    display: flex;
    padding: 16px 40px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 14px;
    background: var(--a, linear-gradient(180deg, #4384D1 0%, #933EFF 100%));
    color: #FAF9FF;
    text-align: center;
    font-family: Manrope;
    font-size: 20px;
    font-style: normal;
    font-weight: 800;

}

.blur {
    filter: blur(259.83782958984375px);
}

.section-para {
    color: var(--para-grey, #949494);
    font-family: Manrope;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0.5px;
}

@media (max-width: 768px) {
    .section-para {
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
    }
}

@media (max-width:400px) {
    .section-para {
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
    }
}

.see-more-btn:hover .see-more-icon {
    width: 28px;
    transition: all 0.2s ease-in-out;
}

.learn {
    background: var(--a, linear-gradient(180deg, #4384D1 0%, #933EFF 100%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: Manrope;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0.5px;
}

.unleash {
    height: 400px;
    border-radius: 16px;
    background: linear-gradient(133deg, rgba(128, 0, 255, 0.60) 0%, rgba(0, 56, 255, 0.60) 47.92%, rgba(128, 0, 255, 0.50) 100%);
}



.unleashH {
    color: #FFF;
    text-align: center;
    font-family: Manrope;
    font-size: 74px;
    font-style: normal;
    font-weight: 800;
    line-height: 96px;
    letter-spacing: -4px;
}

@media (max-width: 720px) {
    .unleashH {
        color: #FFF;
         font-size: 32px;
        font-style: normal;
        font-weight: 800;
        line-height: 40px;
        letter-spacing: 0px;
    }
    .contactBtn {
        display: flex;
        padding: 16px 40px;
        font-size: 12px;

    }

}

.contactBtn {
    display: flex;
    padding: 16px 40px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 14px;
    background: #FFF;
    color: #6679FD;
    text-align: center;
    font-family: Manrope;
    font-size: 20px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
}

.okay {
    border-radius: 14px;
    background: var(--a, linear-gradient(180deg, #4384D1 0%, #933EFF 100%));
}

.drop {
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    border-radius: 8px;
    border: 1px solid #D2D2D2;
    background: #FFF;
    overflow: hidden;
}
