.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.chat1 {
  animation: chat1 3.4s infinite ease;

}


@keyframes chat1 {
  0% {
    left: -50px;
    top: 180px
  }

  30% {
    left: -55px;
    top: 175px
  }

  60% {
    left: -60px;
    top: 180px
  }


  100% {
    left: -50px;
    top: 180px
  }


}


.chat2 {
  animation: chat2 3.4s infinite ease;

}


@keyframes chat2 {
  0% {
    left: 0px;
    top: 60px
  }

  30% {
    left: -5px;
    top: 65px
  }

  60% {
    left: -10px;
    top: 70px
  }


  80% {
    left:  0px;
    top: 65px
  }


  100% {
    left: 0px;
    top: 60px
  }

}


.chat3 {
  animation: chat3 3.4s infinite ease;

}


@keyframes chat3 {
  0% {
    right: -60px;
    top: 180px;
  }

  30% {
    right: -55px;
    top: 185;
  }

  60% {
    right: -50px;
    top: 190px;
  }


  80% {
    right: -54px;
    top: 195px;

  }

  100% {
    right: -60px;
    top: 180px;
  }

}

.chat4 {
  animation: chat4 3.4s infinite ease;

}


@keyframes chat4 {
  0% {
    right: 0px;
    top: 60px;
  }

  30% {
    right: 10px;
    top: 70px;
  }

  60% {
    right: 5px;
    top: 60px;
  }


  80% {
    right: 0px;
    top: 65px;

  }

  100% {
    right: 0px;
    top: 60px;
  }

}