

@font-face {
    font-family: 'Satoshi';
    src: url('../fonts/Satoshi-Bold.otf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Satoshi';
    src: url('../fonts/Satoshi-Light.otf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Satoshi';
    src: url('../fonts/Satoshi-Medium.otf') format('truetype');
    font-weight: 500;
    font-style: normal;
}


@font-face {
    font-family: 'Satoshi';
    src: url('../fonts/Satoshi-Regular.otf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

 