@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@400;500;600;700&display=swap");

body {
  font-family: "Manrope", sans-serif;
}

.text-card3 {
  border-radius: 16px;
  border: 1px solid #bebebe;
  background: #fff;
  box-shadow: 8px 8px 0px 0px rgba(0, 0, 0, 0.3);
  overflow: hidden;
}

body {
  background-color: white;
  font-family: "Manrope";
}

.ai-top-nav {
  background: rgba(255, 255, 255, 0.6);
  backdrop-filter: blur(10px);
}

.better {
  background: var(--a, linear-gradient(180deg, #4384d1 0%, #933eff 100%));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.text-header3 {
  border-bottom: 1px solid;
  border-color: #cfcfcf;
  text-align: center;
  padding-top: 18px;
  padding-bottom: 18px;
  font-size: 24px;
  font-weight: 700;
}

.text-area-field {
  background-color: #ffffff;
  color: #000000;
  width: 100%;
  outline: none;
  padding: 18px 24px;
  height: 300px;
  overflow-y: auto;
  resize: none;
  font-size: 16px;
  font-weight: 400;
}

.text-area-field1 {
  color: black;
  width: 100%;
  outline: none;
  padding: 18px 24px;
  height: 180px;
  overflow-y: auto;
  resize: none;
  font-size: 16px;
  font-weight: 400;
}

.text-area-field::-webkit-scrollbar {
  width: 8px;
}

/* Track */
.text-area-field::-webkit-scrollbar-track {
  background: #d9d7dc;
  cursor: pointer;
}

/* Handle */
.text-area-field::-webkit-scrollbar-thumb {
  background: #848285;
  cursor: pointer;
}

/* Handle on hover */
.text-area-field::-webkit-scrollbar-thumb:hover {
  background: #3e3d3e;
  cursor: pointer;
}

.text-area-field1::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.text-area-field1::-webkit-scrollbar-track {
  background: #848187;
  cursor: pointer;
}

/* Handle */
.text-area-field1::-webkit-scrollbar-thumb {
  background: #49484a;
  cursor: pointer;
}

/* Handle on hover */
.text-area-field1::-webkit-scrollbar-thumb:hover {
  background: #3e3d3e;
  cursor: pointer;
}

.text-area-field::placeholder {
  color: #4f4f4f;
  font-weight: 400;
  font-size: 16px;
}

.output-placeholder {
  color: #4f4f4f;
  font-weight: 400;
  font-size: 16px;
}

.input-footer {
  display: flex;
  align-items: center;
  flex-direction: row;
  padding: 24px 40px;
  width: 100%;
  justify-content: space-between;
  background-color: #f6f6f6;
  min-height: 95px;
  /* overflow: hidden; */
}

.clear-btn {
  background: #ffffff;
  border-radius: 6px;
  padding: 12px;
  font-weight: 600;
  font-size: 14px;
}

.submit-btn {
  background: #1764c8;
  border-radius: 8px;
  padding: 12px 16px;
  font-weight: 600;
  font-size: 14px;
}

.error-div {
  position: absolute;
  display: flex;
  flex-direction: column;
  bottom: 94px;
  height: fit-content;
  width: 100%;
}

.length-error {
  font-size: 12px;
  font-weight: 500;
  color: rgb(235, 30, 30);
  white-space: nowrap;
  background-color: rgb(255, 255, 255);
  padding: 10px;
  padding-left: 24px;
}

.senti-error {
  position: absolute;
  left: 0;
  font-size: 12px;
  font-weight: 400;
  color: rgb(247, 65, 65);
  white-space: nowrap;
}

.feedback-error {
  font-size: 12px;
  font-weight: 400;
  color: rgb(247, 65, 65);
}

@media (max-width: 1024px) {
  .senti-error {
    position: absolute;
    left: auto;
    bottom: 60px;
    font-size: 12px;
    font-weight: 400;
    color: rgb(247, 65, 65);
    white-space: nowrap;
  }
}

.num-error {
  font-size: 12px;
  font-weight: 400;
  color: rgb(247, 65, 65);
  white-space: nowrap;
  background-color: rgb(56, 39, 34);
  padding: 10px;
  padding-left: 24px;
}

.moveTop {
  animation: movetop 3.4s linear;
}

@keyframes movetop {
  0% {
    top: 40px;
  }

  30% {
    top: 20px;
  }

  60% {
    top: -10px;
  }

  100% {
    top: -20px;
  }
}

.moveBottom {
  animation: moveBottom 0.4s linear;
}

@keyframes moveBottom {
  0% {
    margin-top: -60px;
  }

  30% {
    margin-top: -30px;
  }

  60% {
    margin-top: -10px;
  }

  100% {
    margin-top: 0px;
  }
}

.text-copy {
  position: absolute;
  bottom: 40px;
  font-size: 12px;
  font-weight: 500;
}

.out-pr {
  padding-right: 0px !important;
}

.words-span {
  font-weight: 400;
  font-size: 16px;
  color: #000000;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.animate-load {
  animation: spin 1.4s linear infinite;
}

@keyframes pulse {
  50% {
    opacity: 0.2;
  }
}

.animate-pulse3 {
  animation: pulse 2s cubic-bezier(0.3, 0, 0.5, 1) infinite;
}

.recom-head {
  font-size: 40px;
  font-weight: 600;
}

.recom-banner {
  background: #f6f6f6;
  border-radius: 16px;
  padding-top: 50px;
  padding-bottom: 50px;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  justify-content: center;
}

h1 {
  font-size: 70px;
  font-style: normal;
  font-weight: 700;
  line-height: 80px;
  letter-spacing: -2px;
}

.gradient-text {
  /* background: linear-gradient(90.85deg, #1A6BE5 0%, #00FFB3 47.92%, #1A6BE5 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text; */
  color: #ffffff;
}

.explore-btn {
  border-radius: 44px;
}

.explore-btn:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 200%;
  height: 100%;
  background: linear-gradient(
    149.16deg,
    rgba(0, 201, 141, 0.65) 6.68%,
    rgba(204, 0, 255, 0.12) 46.79%,
    rgba(0, 0, 0, 0) 83.02%
  );
}

.explore-btn:hover:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 200%;
  height: 100%;
  background: linear-gradient(
    149.16deg,
    rgba(0, 201, 141, 0.65) 6.68%,
    rgba(204, 0, 255, 0.12) 46.79%,
    rgba(0, 0, 0, 0) 83.02%
  );
}

.explore-btn span {
  position: relative;
  z-index: 2;
  font-size: 1.1rem;
  align-items: center;
  background: #181818;
  border-radius: 44px;
  display: block;
  padding: 16px 50px;
  border-radius: 44px;
}

.explore-btn:hover:before {
  animation: SidebarCreate_rainbowSlide-zxLzf 1s ease-out infinite;
}

@keyframes SidebarCreate_rainbowSlide-zxLzf {
  to {
    transform: translateX(-50%);
  }
}

.explore-btn {
  margin: 0 auto;
  text-align: center;
  position: relative;
  display: block;
  overflow: hidden;
  padding: 1px;
  border-radius: 44px;
  font-weight: 600;
  font-size: 16px;
  margin-top: 30px;
  margin-bottom: 50px;
  cursor: pointer;
}

.explore-btn:hover {
  padding: 2px;
  margin-bottom: 49px;
  margin-top: 29px;
  color: rgba(255, 255, 255, 1);
  box-shadow: 0.5px 0.5px 12px rgb(100, 18, 177, 0.3);
}

.explore-btn:hover {
  margin-bottom: 48px;
}

.ai-card:hover {
  scale: 1.03;
  transition: scale 0.3s ease;
}

.ai-card {
  min-height: 406px;
  background-color: #232325;
}

.ai-card-para {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 37px;
  color: #949494;
}

.try-now:hover .btn-arrow {
  margin-left: 20px;
  transform: margin-left 2.4 linear;
}

.try-now {
  border: 1.5px solid #1a6be5;
  padding: 14px 30px;
  border-radius: 40px;
}

.try-now:hover {
  border: 1.5px solid #0ea2f2;
  padding: 14px 30px;
  border-radius: 40px;
}

.footer-logo {
  font-weight: 500;
  font-size: 24px;
  line-height: 24px;
}

@media (max-width: 640px) {
  .footer-logo {
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
  }

  .footer-right {
    font-size: 10px !important;
    line-height: 16px !important;
  }

  .explore-btn span {
    font-size: 12px;
  }
}

@media (max-width: 968px) {
  h1 {
    /* font-weight: 600; */
    font-size: 50px;
    line-height: 58px;
  }
}


@media (max-width: 768px) {
  h1 {
    /* font-weight: 600; */
    font-size: 32px;
    line-height: 40px;
  }

  .ai-card-para {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 25px;
    color: #949494;
  }

  .ai-card-head {
    font-size: 24px;
  }

  .try-now:hover {
    border: 0;
    padding: 0;
  }
}
@media (max-width: 420px) {
    h1 {
      /* font-weight: 600; */
      font-size: 32px;
      line-height: 40px;
    }
  }

.h1span {
  font-weight: 400;
}

.h2span {
  font-weight: 500;
}

.footer-right {
  /* font-weight: 500; */
  font-size: 14px;
  line-height: 24px;
}

.blog-links {
  font-weight: 500;
  font-size: 17px;
}

.blog-readmore {
  font-weight: 500;
  font-size: 15px;
}

.ai-card-head {
  font-weight: 600;
  font-size: 28px;
}

.active-top-link {
  text-decoration: underline;
}

.blog-card:hover .blog-readmore {
  text-decoration: underline;
 }

.blog-card:hover{
     background: rgb(249, 248, 248);
  }

.nav-links:hover {
  text-decoration: underline;
  cursor: pointer;
}

@media (max-width: 768px) {
  .ai-card-head {
    font-size: 24px;
  }

  .explore-btn span {
    padding: 10px 24px;
  }

  .ai-card:hover {
    scale: 1;
    transition: scale 0.3s ease;
  }
}

.rec-bg {
  background: #363636;
  opacity: 0.1;
  border-radius: 16px;
}

.glow-on-hover {
  min-height: 50px;
  border: none;
  outline: none;
  color: #fff;
  background: #111;
  cursor: pointer;
  position: relative;
  z-index: 0;
  border-radius: 60px;
  border: 1.4px solid #ffffff;
}

.glow-on-hover:before {
  content: "";
  background: linear-gradient(
    45deg,
    #ef1a8c,
    #f4d50f,
    #9ff713,
    #47c515,
    #32c0a8,
    #1939db,
    #6806d1,
    #ff00c8,
    #f162a0
  );
  position: absolute;
  top: -2px;
  left: -2px;
  background-size: 400%;
  z-index: -1;
  filter: blur(5px);
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  animation: glowing 20s linear infinite;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  border-radius: 60px;
}

.glow-on-hover:active {
  color: #000;
}

.glow-on-hover:active:after {
  background: transparent;
}

.glow-on-hover:hover:before {
  opacity: 1;
}

.glow-on-hover:after {
  z-index: -1;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: #111;
  left: 0;
  top: 0;
  border-radius: 60px;
}

@keyframes glowing {
  0% {
    background-position: 0 0;
  }

  50% {
    background-position: 400% 0;
  }

  100% {
    background-position: 0 0;
  }
}

.delete-tooltip {
  font-size: 14px !important;
}

.explore-new {
  border-radius: 14px;
  background-color: #4384d1;
  background-image: #4384d1;
  background-image: -moz-linear-gradient(top, #933eff 0%, #4384d1 100%);
  background-image: -webkit-linear-gradient(top, #933eff 0%, #4384d1 100%);
  background-image: linear-gradient(to bottom, #933eff 0%, #4384d1 100%);
  background-size: 300px;
  background-repeat: no-repeat;
  background-position: 0%;
  -webkit-transition: background 300ms ease-in-out;
  transition: background 300ms ease-in-out;
  padding: 16px 40px;
  font-size: 20px;
  font-style: normal;
  font-weight: 800;
  color: #ffffff;
}
.explore-new:hover {
  background-position: -200%;
  box-shadow: 6px 6px #5d28a1;
  transition: 0.8s;
}

.explore {
  display: flex;
  padding: 16px 40px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  background: var(--a, linear-gradient(180deg, #4384d1 0%, #933eff 100%));
  color: #faf9ff;
  text-align: center;
  font-family: Manrope;
  font-size: 20px;
  font-style: normal;
  font-weight: 800;
}

.contact-btn {
  display: flex;
  padding: 16px 40px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 14px;
  background: #fff;
  color: #6679fd;
  text-align: center;
  font-family: Manrope;
  font-size: 20px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  margin-bottom: 20px;
}

.contact-btn:hover{
    box-shadow: 0px 15px 50px -15px #111;
    transition: 0.4s;
}
 